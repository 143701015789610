import { TranslateService } from '@ngx-translate/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown'
import { ApiService } from '../../../services/api.service'
import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core'
import { ConstantsService } from 'src/app/services/constants.service'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: false, autoClose: true } }]
})
export class HeaderComponent implements OnInit {
    isCollapsed = true
    carSubscribe: any
    isAuthenticated = false
    isAdmin = false
    isStudent = false
    isSubAdmin = false
    isInstructor = false
    languages = this.cs.LANGUAGES
    lang: any
    userType = ''
    constructor(
        public api: ApiService,
        public cs: ConstantsService,
        public router: Router,
        private route: ActivatedRoute,
        public renderer2: Renderer2,
        public ts: TranslateService
    ) {
        this.ts.currentLang = this.api.getCurrentLang()
        api.userLoggedInObs.subscribe(m => {
            this.isAuthenticated = m
            if (this.isAuthenticated) {
                this.loginUpdates()
            }
        })

        this.lang = this.api.translate('website.header')
        this.lang.subscribe((d: any) => {
            this.lang = d
        })
    }
    loginUpdates(): void {
        // this.isAdmin = this.api.isAdmin()
        // this.isStudent = this.api.isStudent()
        // this.isSubAdmin = this.api.isSubAdmin()
        // this.isInstructor = this.api.isInstructor()

        // if(this.api.isAdmin()){
        //     this.router.navigate(['/admin/dashboard'])
        // }
        // if(this.api.isStudent()){
        //     this.router.navigate(['/student/dashboard'])
        // }
        // if(this.api.isSubAdmin()){
        //     this.router.navigate(['/sub-admin/dashboard'])
        // }
        // if(this.api.isInstructor()){
        //     this.router.navigate(['/firm-owner/dashboard'])
        // }
    }

    ngOnInit() {
    }

    logOut(): void {
        this.api.logOutSession().subscribe((resp: any) => {
            const check = this.api.logOut()
            if (check) {
                location.reload()
            }
        })
    }

    setCollapsed() {
        this.isCollapsed = !this.isCollapsed
    }
}
