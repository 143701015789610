import { ActivatedRoute, Event, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ThemeService } from './services/theme.service'
import { Observable } from 'rxjs'
import { MessagingService } from './services/messaging.service'
import { ApiService } from './services/api.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    isLoading: boolean

    constructor(
        public messaging: MessagingService,
        public route: Router,
        public router: ActivatedRoute,
        private api: ApiService,
        private themeService: ThemeService,
        
    ) {
        this.route.events.subscribe((routerEvent: Event) => {
            
            
        })
    }

    ngOnInit() {
        this.themeService.setTheme('default')
        this.messaging.requestPermission()
        this.messaging.receiveMessage()
        this.messaging.recieveMessageNotification()
    }

    
}
