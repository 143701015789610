import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { getMessaging, getToken } from "firebase/messaging";
import { BehaviorSubject } from 'rxjs';
import { IAlertService } from '../libs/ialert/ialerts.service';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    fcmToken: any = null
    currentMessage = new BehaviorSubject(null)

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private alert: IAlertService,
        public api: ApiService
    ) {}

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.fcmToken = token
                // console.log('fbt----',token)
            },
            (err) => {
                console.error('Unable to get permission to notify.', err)
            }
        )
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((message: any) => {
            console.log('message-notification----', message);
            if(message?.data?.tnotification){
                    console.log('message-notification', message);
                    console.log('totaln', message?.data?.tnotification);

                    this.api.notification.next(message?.data?.tnotification)
            }

            // let m = JSON.parse(message.notification?.body)
            // let b = JSON.parse(m.body)
            //console.log('m', m);
            //console.log(b,'b');
            this.alert.success(message.notification?.title + '  Message: '+message.notification?.body )
         })
    }
    recieveMessageNotification(){
         this.angularFireMessaging.messages.subscribe((message: any) => {
            console.log('message-notification----', message);
            // if(message?.data?.tnotification){
            //         console.log('message-notification', message);
            //         console.log('totaln', message?.data?.tnotification);

            //         //this.api.notification.next(message?.data?.tnotification)
            // }

            // let m = JSON.parse(message.notification?.body)
            // let b = JSON.parse(m.body)
            // console.log('m', m);
            // console.log(b,'b');
            this.alert.success(message.notification?.title + '  Message: '+ message.notification?.body )
         })
    }
}
