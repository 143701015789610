export const environment = {
    production: false,
    // firebase: {
    //     apiKey: "AIzaSyByyNM8I33zzIxhVFBeKTBckXl05WQTE3w",
    //     authDomain: "pythone-c7465.firebaseapp.com",
    //     projectId: "pythone-c7465",
    //     storageBucket: "pythone-c7465.appspot.com",
    //     messagingSenderId: "17703764684",
    //     appId: "1:17703764684:web:5258fa5f01954df7e4b869",
    //     measurementId: "G-VD13HPYN1X"
    // }
    firebase:  {
        apiKey: "AIzaSyB_N0jLmhgdc9rDeomjSSsAjQRLsb2Cm4Q",
        authDomain: "python-all-stars.firebaseapp.com",
        projectId: "python-all-stars",
        storageBucket: "python-all-stars.appspot.com",
        messagingSenderId: "1039897605726",
        appId: "1:1039897605726:web:6d3325069d1f52f80666cf",
        measurementId: "G-T28RM1JYT8"
      }
}

export const apis = {
     baseUrl: 'https://python-api.codegenio.com/api',
   // baseUrl: 'https://python-api.unitedcheerleading.com/api',
   // baseUrl: 'http://localhost:3014/api',
    googleApiKey: 'AIzaSyCq-_3Fqmw-4Y6tdKFToydCYdVaprzHTjA',
    googleCaptchaSiteKey: ''
}

export const socialLoginUrls = {
    google: `${apis.baseUrl}/public/login/google`,
    facebook: `${apis.baseUrl}/public/login/facebook`
}

export const appURL = 'https://python-api.codegenio.com'
